import React, { FC } from 'react';

interface TitleProps {
    text: object;
}

const Text: FC<TitleProps> = ({ text }) => {
    return (
        <div className='text-left text' style={{ color: "black", textAlign: "justify" }}>
            {text}
        </div>
    );
};

export default Text;