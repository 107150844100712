import clsx from 'clsx'
import React, { FC } from 'react'
import { KTSVG } from '../../../helpers'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-40px h-40px w-md-40px h-md-40px'

const Topbar: FC = () => {

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx('btn btn-icon btn-active-light-primary pulse pulse-primary btn-custom', toolbarButtonHeightClass)}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com002.svg'
            className="svg-icon-3x svg-icon-primary"
          />
          <span className="pulse-ring border-5"></span>
        </div>
      </div>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
      </div>
    </div>
  )
}

export { Topbar }
