import React, { FC } from 'react';

interface TitleProps {
    src: string;
    alt: string;
    classname: string;
}

const Image: FC<TitleProps> = ({ src, alt, classname }) => {
    return (
        <img
            src={src}
            alt={alt}
            className={classname}
        />
    );
};

export default Image;