import React, { forwardRef } from "react";
import ImageArray from "./ImageArray";
import Card from "../components/Card";
// import CardDownload from "../components/CardDownload";
import logokare from '../media/logo-style.png';
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const ImageSequence2 = forwardRef(({ progress }, ref) => {
  const newImages2 = ImageArray();

  const index = Math.round(progress * 1 * (newImages2.length - 1));
  if (newImages2[index], [1] === "loading") {
  } else {
    return (
      <div
        ref={ref}
        key={index}
        style={{
          display: "block",
          minHeight: "100vh",
          minWidth: "100%",
          backgroundImage: `url('${newImages2[index][0] && newImages2[index][0].src}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          WebkitTransform: "rotate(180deg)",
          transform: "rotate(180deg)",
        }}>
        <div className="container p-10" style={{ WebkitTransform: "rotate(180deg)", transform: "rotate(180deg)", }}>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-3 col-md-3 col-4">
              <Card img={toAbsoluteUrl("/media/icons/duotune/general/gen004.svg")} to="/kkdik" buttonText="Explore KKDİK" isExternal={false} />
            </div>

            <div className="col-lg-3 col-md-3 col-4">
              <Card img={logokare} to="https://www.dgroute.com/en/" buttonText="Visit Us" isExternal={true} />
            </div>

            <div className="col-lg-3 col-md-3 col-4">
              <Card img={toAbsoluteUrl("/media/icons/duotune/communication/com011.svg")} to="./contact" buttonText="Contact Us" isExternal={false} />
            </div>
          </div>

          <div className="row justify-content-center" style={{ height: "50vh" }} />
        </div>
      </div>
    );
  }
}
);

export default ImageSequence2;