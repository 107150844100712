import { FC } from 'react'
import { Helmet } from 'react-helmet';

const Error500: FC = () => {
  return (
    <>
      <Helmet>
        <title>Error 505 | GHS Compliance</title>
        <meta name="description" content="Error 500" />
      </Helmet>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>System Error</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        Something went wrong! <br /> Please try again later.
      </div>
    </>
  )
}

export { Error500 }
