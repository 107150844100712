import React, { FC } from 'react';

interface TitleProps {
    head: string
    title: string
    body: string
    footer: string
}

const GoogleMap: FC<TitleProps> = ({ head, title, body, footer }) => {
    return (
        <div className="card shadow" style={{ backgroundColor: "#fff", minHeight: "20rem" }}>
            <div className="card-header ribbon ribbon-top ribbon-vertical">
                <div className="ribbon-label bg-primary">
                    <i className="bi bi-telephone-fill fs-2 text-white"></i>
                </div>
                <div className="card-title">
                    <h2 className="text-primary">
                        <b>{head}</b>
                    </h2>
                </div>
            </div>
            <div className="card-body">
                <div className="text-center card-rounded d-flex flex-column justify-content-center h-100">
                    <div className="text-black fw-bolder">
                        <h3 className="text-primary">
                            <b>{title}</b>
                        </h3>
                    </div>
                    <div className="text-black fw-bold">{body}</div>
                    <a href="tel:+90 262 33 00 237" className="fw-bold fs-4" style={{ color: "#0095E8" }}>
                        <b>{footer}</b>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default GoogleMap;
