import React, { useRef } from "react";
import { Controller, Scene } from 'react-scrollmagic';
import Typewriter from "typewriter-effect";
import Sequence from "./Sequence";
import Sequence2 from "./Sequence2";
import TextCard from "./components/TextCard";
import Text from "./components/Text";
import "./styles.css";
import GoogleMap from "./components/GoogleMap";
import { Helmet } from 'react-helmet';

const screenWidth = window.innerWidth;

var durationSpeed = 1000;

if (screenWidth < 768) {
    durationSpeed /= 1.3;
}

export function Home() {
    const ref = useRef();

    return (
        <>
            <Helmet>
                <title>Home | GHS Compliance</title>
                <meta name="description" content="KKDIK requires all companies manufacturing or placing a substance on Turkish market in quantities greater than 1t/year to register that substance with the MoEU." />
            </Helmet>
            <div className="container-fluid p-0" style={{ backgroundColor: "transparent", position: "relative" }}>

                <div className="text-center display-1" style={{ position: "absolute", top: "5%", left: "3%", zIndex: "10" }}>
                    <Typewriter
                        options={{
                            wrapperClassName: "text-muted",
                            cursorClassName: "text-muted",
                            skipAddStyles: true,
                            strings: ['Hello', 'It\'s nice to see you'],
                            autoStart: true,
                            loop: true,
                            cursor: ".|",
                        }}
                    />
                </div>
                <div>
                    <Controller>
                        <Scene classToggle="after" triggerHook={0.7} reverse={true}>
                            <div className="before">
                                <div id="seq" style={{ opacity: "0.9" }}>
                                    <Controller>
                                        <Scene duration={durationSpeed} triggerHook={0.1} triggerElement={"#seq"} >
                                            {(progress: any) => (
                                                <Sequence ref={ref} {...progress = { progress }} />
                                            )}
                                        </Scene>
                                    </Controller>
                                </div>

                                <div id="seq2" style={{ opacity: "0.9" }}>
                                    <Controller>
                                        <Scene duration={durationSpeed} triggerHook={0.5} triggerElement={"#seq2"} >
                                            {(progress: any) => (
                                                <>
                                                    <Sequence2 ref={ref} {...progress = { progress }} />
                                                    {/* <div style={{ backgroundImage: "linear-gradient(to top, rgba(255,0,0,0), rgba(0,0,0,1))", height: "5vh" }}></div> */}
                                                </>
                                            )}
                                        </Scene>
                                    </Controller>
                                </div>

                                <div>
                                    <Controller>
                                        <Scene classToggle="after" triggerHook={0.7} reverse={true}>
                                            <div className="before">
                                                <div className="container p-10">
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-9">
                                                            <TextCard
                                                                css={{ backgroundColor: "" }}
                                                                csstext={{ color: "black", fontSize: "1.5rem", textAlign: "justify" }}
                                                                csstitle={{ color: "black", fontSize: "3rem" }}
                                                                header="T-REACH (KKDIK) OR Service"
                                                                text={<>KKDIK requires all companies manufacturing or placing a substance on Turkish market in quantities greater than 1t/year to register that substance with the MoEU. For legal reasons, only companies with a legal entity in Turkey are allowed to submit a registration; however, non-TR companies may submit registration by appointing a TR-based Only Representative to register on their behalf, in which case their importers will be regarded as downstream users and do not need to do registrations.</>}
                                                                ish1={true} />
                                                        </div>
                                                        <div className="col-md-3">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Scene>
                                    </Controller>
                                </div>

                                <div>
                                    <Controller>
                                        <Scene classToggle="after" triggerHook={0.7} reverse={true}>
                                            <div className="before">
                                                <div className="container px-10 py-10">
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-3">
                                                        </div>
                                                        <div className="col-md-9">
                                                            <Text text={<>Benefits of Appointing REACH Only Representative (OR) Relieve importers of their obligations to register and obtain continued market access in Turkey (many TR importers will try to avoid registrations by purchasing KKDIK registered chemicals); Avoid dependence on a single importer and keep market access should one TR importer cease trading; Gain advantages over other non-TR suppliers who do not appoint OR to register their substances.</>} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Scene>
                                    </Controller>
                                </div>

                                <div>
                                    <Controller>
                                        <Scene classToggle="after" triggerHook={0.8} reverse={true}>
                                            <div className="before">
                                                <div className="container px-10 py-10">
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-9">
                                                            <Text text={<>Note: Importers will be exempt from KKDIK registration if their non-TR suppliers have registered; however, importers need to confirm with their suppliers' Only Representative that they are included in the inventory of importers and their tonnage and uses are covered by the OR. This can be done by asking for KKDIK Certificate of Compliance and Tonnage Coverage Certificate from the only representative of their suppliers before they put chemicals on the TR market.</>} />
                                                        </div>

                                                        <div className="col-md-3">
                                                        </div>
                                                    </div>
                                                </div>

                                                <GoogleMap css={{ backgroundColor: "#fff", height: "%90", opacity: "0.8" }} />
                                            </div>
                                        </Scene>
                                    </Controller>
                                </div>
                            </div>
                        </Scene>
                    </Controller>
                </div>
            </div>
        </>
    );
}