import React from "react";
import kkdiklogo from './media/kkdik-logo.png';
import TextCard from "./components/TextCard"
import Image from "./components/Image";
import Link from "./components/Link";
import Text from "./components/Text"
import He3 from "./components/He3"
import Ul from "./components/Ul"
import Li from "./components/Li";
import { Controller, Scene } from "react-scrollmagic";
import { Helmet } from 'react-helmet';

export function Treach3() {
    return (
        <>
            <Helmet>
                <title>What is KKDIK OR</title>
                <meta name="description" content="Non-Turkish manufacturers are not permitted to submit KKDİK (Turkish REACH) registration dossiers to the Ministry of Environment (MoE)." />
            </Helmet>
            <div>
                <Controller>
                    <Scene classToggle="after" triggerHook={0.7} reverse={true}>
                        <div className="before">
                            <div className="container">
                                <div className="row justify-content-center my-3">
                                    <TextCard
                                        css={{ backgroundColor: "#ffffff", marginTop: "5rem", padding: "4rem" }}
                                        csstext={{ color: "black", fontSize: "1.5rem", textAlign: "justify" }}
                                        csstitle={{ color: "black", fontSize: "3rem" }}
                                        header="What is KKDIK OR"
                                        text={
                                            <>
                                                <Text text={
                                                    <>
                                                        Non-Turkish manufacturers are not permitted to submit KKDİK (Turkish REACH) registration dossiers to the Ministry of Environment (MoE). However, KKDİK provides for a non-Turkish manufacturer to appoint an Only Representative in order to fulfill the registration obligations on their behalf. DGROUTU is a Only representative in Turkey.
                                                    </>
                                                } />

                                                <Text text={
                                                    <>
                                                        KKDIK requires all companies manufacturing or placing a substance on Turkish market in quantities greater than 1t/year to register that substance with the MoEU. For legal reasons, only companies with a legal entity in Turkey are allowed to submit a registration. However, non-TR companies may submit registration by appointing a TR-based Only Representative to register on their behalf, in which case their importers will be regarded as downstream users and do not need to do registrations.
                                                    </>
                                                } />

                                                <He3 text={<><a
                                                    href="https://www.dgroute.com/en/t_reach_only_representative_kkdik.html"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="justify-content-center"
                                                >
                                                    Benefits of Appointing REACH Only Representative (OR)
                                                </a></>} />

                                                <Ul list={
                                                    <>
                                                        <Li text={<>
                                                            Relieve importers of their obligations to register and obtain continued market access in Turkey (many TR importers will try to avoid registrations by purchasing KKDIK registered chemicals);
                                                        </>} />

                                                        <Li text={<>
                                                            Avoid dependence on a single importer and keep market access should one TR importer cease trading;
                                                        </>} />

                                                        <Li text={<>
                                                            Gain advantages over other non-TR suppliers who do not appoint OR to register their substances;
                                                        </>} />
                                                    </>
                                                } />

                                                <Text text={
                                                    <>
                                                        Note: Importers will be exempt from KKDIK registration if their non-TR suppliers have registered. However, importers need to confirm with their suppliers’ Only Representative that they are included in the inventory of importers and their tonnage and uses are covered by the OR. This can be done by asking for KKDIK Certificate of Compliance and Tonnage Coverage Certificate from the only representative of their suppliers before they put chemicals on the TR market.
                                                    </>
                                                } />

                                                <Text text={
                                                    <>
                                                        The service provides the necessary legal presence and representation in Turkey including representation in MoE, technical management of KKDİK pre-registration and registration, and submission of information to the MoE to fulfill a company’s KKDİK obligations. Whether your company is entering Turkey market for the first time or your business is considering a change of OR, we can offer a comprehensive service tailored to your unique requirements.
                                                    </>
                                                } />

                                                <div className="d-flex justify-content-center">
                                                    <a
                                                        href="https://www.dgroute.com/en/kkdik.html"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="justify-content-center"
                                                    >
                                                        <Image src={kkdiklogo} alt="Logo Kare" classname="w-100" />
                                                    </a>
                                                </div>

                                                <Text text={
                                                    <>
                                                        <Link to="/contact" css={{}} className={""} Text={"Do not hesitate to contact us for comprehensive information and offer about Only Representative(OR services) and Turkish REACH Regulations."} />
                                                    </>
                                                } />
                                            </>
                                        }
                                        ish1={true}
                                    />
                                </div>
                            </div >

                        </div>
                    </Scene>
                </Controller>
            </div>
        </>
    );
}