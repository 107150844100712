import React from "react";
import kkdiklogo from './media/kkdik-logo.png';
import TextCard from "./components/TextCard"
import Link from "./components/Link";
import TextImage from "./components/TextImage";
import Text from "./components/Text"
import He3 from "./components/He3"
import Ul from "./components/Ul"
import Li from "./components/Li";
import { Controller, Scene } from "react-scrollmagic";
import { Helmet } from "react-helmet";

export function Services2() {
    return (
        <>
            <Helmet>
                <title>KKDIK (T-REACH) Only Representative Services</title>
                <meta name="description" content="A non-Turkish company cannot register substances under KKDIK (T-REACH). But DGROUTE can do this for you company." />
            </Helmet>
            <div>
                <Controller>
                    <Scene classToggle="after" triggerHook={0.7} reverse={true}>
                        <div className="before">
                            <div className="container">
                                <div className="row justify-content-center my-3">
                                    <TextCard
                                        css={{ backgroundColor: "#ffffff", marginTop: "5rem", padding: "4rem" }}
                                        csstext={{ color: "black", fontSize: "1.5rem", textAlign: "justify" }}
                                        csstitle={{ color: "black", fontSize: "3rem" }}
                                        header="KKDIK (T-REACH) Only Representative Services"
                                        text={
                                            <>
                                                <Text text={
                                                    <>
                                                        A non-Turkish company cannot register substances under <Link to="/kkdik" css={{}} className={"text-primary"} Text={"KKDIK (T-REACH)"} />. But they can appoint an <a
                                                            href="https://www.dgroute.com/en/t_reach_only_representative_kkdik.html"
                                                            target="_blank"
                                                            className="text-primary"
                                                            rel="noreferrer"><b>Only Representative</b></a>  who is legally responsible for ensuring its non-Turkish companies substances are compliant with KKDIK. The appointment of an Only Representative has many advantages for the non-Turkish manufacturer:
                                                    </>
                                                } />

                                                <Ul list={
                                                    <>
                                                        <Li text={<>
                                                            An independent third party takes on all obligations of the importers under KKDIK (T-REACH) . The Importers then become downstream users.
                                                        </>} />
                                                        <Li text={<>
                                                            If an importer registers a product the importing company will own the registration. It may then be more difficult for the manufacturer to expand or change the route to the Turkish market. With an OR the non-Turkish manufacturer owns the registration and can therefore export to as many different Turkish importers as they want.
                                                        </>} />
                                                        <Li text={<>
                                                            If an importer changes supplier the non-Turkish manufacturer may lose access to the Turkish market.
                                                        </>} />
                                                    </>
                                                } />

                                                <br />
                                                <He3 text={<>Turkish REACH Regulation</>} />
                                                <Text text={
                                                    <>
                                                        For substances the Only Representative will make, subject to certain conditions being fulfilled, a late pre-registration to the Ministry of Environment. The submission of a late pre-registration permits a non-Turkish manufactuer to supply into the Turkish and take advantage of the extended registration deadline program. Subsequently the Only Representative undertakes all duties as are necessary to maintain KKDIK compliance, including documentation, record keeping and preparation and submission of a registration dossier.
                                                        <br /><br />
                                                        For substances the Only Representative will prepare an inquiry dossier and negotiate with MoEU or data holders to gain access to the data required to complete a registration. If any testing is prescribed by KKDIK (T-REACH) the Only Representative will organise and monitor this testing to ensure compliance. The Only Representative will prepare and submit the registration dossier to MoE. OR will be the first contact for any communication from MoEU or Member State Competent Authorities.
                                                    </>
                                                } />

                                                <TextImage href="https://www.dgroute.com/en/kkdik.html" src={kkdiklogo} alt="KKDİK Logo" classname="" />

                                                <Text text={
                                                    <>
                                                        <Link to="/contact" css={{}} className={""} Text={"Do not hesitate to contact us for comprehensive information and offer about Only Representative(OR services) and Turkish REACH Regulations."} />
                                                    </>
                                                } />
                                            </>
                                        }
                                        ish1={true}
                                    />
                                </div>
                            </div >
                        </div>
                    </Scene>
                </Controller>
            </div>
        </>
    );
}