import React, { FC } from 'react';

interface TitleProps {
    header: string;
    text: object;
    css: object;
    csstext: object;
    csstitle: object;
    ish1: boolean;
}

const TextCard: FC<TitleProps> = ({ text, header, css, csstext, csstitle, ish1 }) => {

    let tag = <h2 className='h2 pb-5' style={csstitle}>{header}</h2>

    if (ish1) {
        tag = <h1 className='h2 pb-5' style={csstitle}>{header}</h1>
    }
    return (
        <div className="rounded" style={css}>
            {tag}
            <hr style={{ color: "black" }} />
            <div className='text' style={csstext}>{text}</div>
        </div>
    );
};

export default TextCard;