import React from "react";
import kkdiklogo from './media/kkdik-logo.png';
import TextCard from "./components/TextCard"
import Image from "./components/Image";
import Link from "./components/Link";
import Text from "./components/Text"
import He3 from "./components/He3"
import { Controller, Scene } from "react-scrollmagic";
import { Helmet } from "react-helmet";

export function Services3() {
    return (
        <>
            <Helmet>
                <title>KKDIK SIEF Management</title>
                <meta name="description" content="You are obliged to communicate on several issues in the supply chain and respectively in the SIEF (MBDF) under KKDİK regulation.​ SIEF management is also offered under KKDIK registration services." />
            </Helmet>
            <div>
                <Controller>
                    <Scene classToggle="after" triggerHook={0.7} reverse={true}>
                        <div className="before">
                            <div className="container">
                                <div className="row justify-content-center my-3">
                                    <TextCard
                                        css={{ backgroundColor: "#ffffff", marginTop: "5rem", padding: "4rem" }}
                                        csstext={{ color: "black", fontSize: "1.5rem", textAlign: "justify" }}
                                        csstitle={{ color: "black", fontSize: "3rem" }}
                                        header="KKDIK SIEF Management"
                                        text={
                                            <>
                                                <Text text={<>You are obliged to communicate on several issues in the supply chain and respectively in the SIEF (MBDF) under <Link to="/treach1" css={{}} className={"text-primary"} Text={"KKDİK regulation"} />.​ SIEF management is also offered under KKDIK registration services</>} />

                                                <br />

                                                <He3 text={<>KKDIK registration services</>} />

                                                <Text text={<>
                                                    DGROUTE, KKDIK registration services provide full support for the requirements of KKDIK registration. We offer a special service for KKDİK registration, pre-registration (pre-SIEF), SIEF participation, actors communication with supply chain, exposure scenarios, risk assessments, technical dossier preparation, chemical safety assessments (CSA), chemical safety reports (CSR) including for any stage of companies.
                                                    <br /><br />
                                                    The principle is “one substance, one registration”.
                                                    During joint submission process, the lead registrant or KKDİK (Turk REACH) consortium will do most of the work such as data collection, development of technical dossier and Chemical Safety Report (CSR), and submission of joint registration dossier to CRS.
                                                    <br /><br />
                                                    Other co-registrants only need to pay the lead registrant or consortium a fee to refer to the joint registration dossier and then prepare the individual part of the registration dossier in Turkey Chemical Registration System.
                                                    <br /><br />
                                                    In SIEF communication, quite a number of information especially relating to data needs to be collected from or fed back to pre-SIEF and co-registrants. You also need to agree on the substance Identify, the Lead registrant, on the classification and labelling, and crucial information needs to be communicated to the authorities.
                                                    <br /><br />
                                                    <a
                                                        href="https://www.dgroute.com/en/t_reach_only_representative_kkdik.html"
                                                        target="_blank"
                                                        className="text-primary"
                                                        rel="noreferrer"><b>DGROUTE</b>​</a> helps you find out the intentions of your co-registrants, and it guarantees the best approach to contacting downstream users to gather information. It helps you store all communication and make sure information previously distributed is available, and communicate to the authorities, including MoEU and its different bodies.
                                                    <br /><br />
                                                    MBDF : Madde Bilgi Değişim Forumu
                                                    <br /><br />
                                                    <Link to="/kkdik" css={{}} className={"text-primary"} Text={"KKDİK : KİMYASALLARIN KAYDI, DEĞERLENDİRİLMESİ, İZNİ VE KISITLANMASI (Registration, Evaluation, Authorization and Restriction of Chemicals)"} />
                                                    <br /><br />
                                                    SIEF : Substance Information Exchange Forum
                                                </>} />

                                                <div className="d-flex justify-content-center">
                                                    <a
                                                        href="https://www.dgroute.com/en/kkdik.html"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="justify-content-center"
                                                    >
                                                        <Image src={kkdiklogo} alt="Logo Kare" classname="w-100" />
                                                    </a>
                                                </div>

                                                <Text text={<><Link to="/contact" css={{}} className={"text-primary"} Text={"Do not hesitate to contact us for comprehensive information and offer about KKDIK Pre-Registration Service and Turkish REACH Regulations."} /></>} />
                                            </>
                                        }
                                        ish1={true}
                                    />
                                </div>
                            </div >
                        </div>
                    </Scene>
                </Controller>
            </div>
        </>
    );
}