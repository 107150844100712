import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { Home } from "../pages/Home"
import { About } from '../pages/About'
import { Contact } from '../pages/Contact'
import { Treach1 } from '../pages/Treach1'
import { Treach2 } from '../pages/Treach2'
import { Treach3 } from '../pages/Treach3'
import { Services1 } from '../pages/Services1'
import { Services2 } from '../pages/Services2'
import { Services3 } from '../pages/Services3'
import { Services4 } from '../pages/Services4'
import { Services5 } from '../pages/Services5'
import { Kkdik } from '../pages/Kkdik'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="treach" element={<Treach1 />} />
        <Route path="about-kkdik" element={<Treach2 />} />
        <Route path="what-is-kkdik-or" element={<Treach3 />} />
        <Route path="services" element={<Services1 />} />
        <Route path="only-representative" element={<Services2 />} />
        <Route path="sief-management" element={<Services3 />} />
        <Route path="pre-registration" element={<Services4 />} />
        <Route path="sds-authoring" element={<Services5 />} />
        <Route path="kkdik" element={<Kkdik />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }
