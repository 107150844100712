import React, { FC } from 'react';

interface TitleProps {
    css: object;
}

const GoogleMap: FC<TitleProps> = ({ css }) => {
    return (
        <div className="card card-rounded card-custom card-stretch p-0 m-0" style={css}>
            <iframe title='googlemap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28738.080540422066!2d29.44970712593294!3d40.790199912884766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb20fee2d1887d%3A0xdad144897898ff77!2sRoute%20Yazilim%20ve%20Dan%C4%B1%C5%9Fmanl%C4%B1k%20Sanayi%20ve%20Ticaret%20Limited%20%C5%9Eirketi!5e0!3m2!1str!2str!4v1654672657735!5m2!1str!2str" width="100%" height="300" style={{ border: "0" }} loading="lazy"></iframe>
        </div>
    );
};

export default GoogleMap;