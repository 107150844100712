import React, { FC } from 'react';

interface TitleProps {
    text: object;
}

const He3: FC<TitleProps> = ({ text }) => {
    return (
        <h3 className='h1'>{text}</h3>
    );
};

export default He3;