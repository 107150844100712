import {FC} from 'react'
import { Helmet } from 'react-helmet';

const Error404: FC = () => {
  return (
    <>
    <Helmet>
                <title>Error 404 | GHS Compliance</title>
                <meta name="description" content="Error 404" />
            </Helmet>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Page Not Found</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        The page you looked not found! <br />
      </div>
    </>
  )
}

export {Error404}
