import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface TitleProps {
    to: string;
    css: object;
    className: string;
    Text: string;
}

const Image: FC<TitleProps> = ({ to, css, className, Text }) => {
    return (
        <Link
            to={to}
            style={css}
            rel="noreferrer"
            className={className}
        >
            <b>{Text}</b>
        </Link>
    );
};

export default Image;