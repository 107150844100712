import React from 'react'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'

export function MenuInner() {
  return (
    <>
      <MenuItem title='Home' to='/' />
      <MenuItem title='About' to='/about' />
      <MenuInnerWithSub
        title='Treach'
        to='/treach'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/treach' title='KKDIK(T-REACH)' hasBullet={true} />
        <MenuItem to='/about-kkdik' title='About KKDIK' hasBullet={true} />
        <MenuItem to='/what-is-kkdik-or' title='What is KKDIK OR' hasBullet={true} />
        <MenuItem to='/kkdik' title='Review KKDIK' hasBullet={true} />
      </MenuInnerWithSub>

      <MenuInnerWithSub
        title='Services'
        to='/services'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/services' title='Services' hasBullet={true} />
        <MenuItem to='/only-representative' title='KKDIK (T-REACH) Only Representative Services' hasBullet={true} />
        <MenuItem to='/sief-management' title='KKDIK SIEF Management' hasBullet={true} />
        <MenuItem to='/pre-registration' title='KKDIK Pre-Registration Services' hasBullet={true} />
        <MenuItem to='/sds-authoring' title='Turkish SDS Authoring Service' hasBullet={true} />
      </MenuInnerWithSub>

      <MenuItem title='Contact' to='/contact' />
    </>
  )
}
