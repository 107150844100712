import React, { FC } from 'react';

interface TitleProps {
    list: object;
}

const Ul: FC<TitleProps> = ({ list }) => {
    return (
        <ul>
            {list}
        </ul>
    );
};

export default Ul;