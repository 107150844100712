import React from "react";
import kkdiklogo from './media/kkdik-logo.png';
import TextCard from "./components/TextCard"
import Image from "./components/Image";
import Link from "./components/Link";
import Text from "./components/Text"
import He3 from "./components/He3"
import Ul from "./components/Ul"
import Li from "./components/Li";
import { Controller, Scene } from "react-scrollmagic";
import { Helmet } from 'react-helmet';

export function Treach2() {
    return (
        <>
            <Helmet>
                <title>About KKDIK</title>
                <meta name="description" content="According to the Turkish REACH regulation, Companies are responsible for collecting information on the properties and uses of the substances they manufacture or import above one tonne a year." />
            </Helmet>
            <div>
                <Controller>
                    <Scene classToggle="after" triggerHook={0.7} reverse={true}>
                        <div className="before">
                            <div className="container">
                                <div className="row justify-content-center my-3">
                                    <TextCard
                                        css={{ backgroundColor: "#ffffff", marginTop: "5rem", padding: "4rem" }}
                                        csstext={{ color: "black", fontSize: "1.5rem", textAlign: "justify" }}
                                        csstitle={{ color: "black", fontSize: "3rem" }}
                                        header="About KKDIK"
                                        text={
                                            <>
                                                <He3 text={<>Turkish REACH regulation</>} />

                                                <div className="d-flex justify-content-center">
                                                    <a
                                                        href="https://www.dgroute.com/en/kkdik.html"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="justify-content-center"
                                                    >
                                                        <Image src={kkdiklogo} alt="Logo Kare" classname="w-100" />
                                                    </a>
                                                </div>

                                                <He3 text={<>Turkish REACH regulation</>} />

                                                <Text text={
                                                    <>
                                                        According to the <Link to="/kkdik" css={{}} className={"text-primary"} Text={"Turkish REACH"} /> regulation, Companies are <Link to="/services2" css={{}} className={"text-primary"} Text={"responsible"} /> for collecting information on the properties and uses of the substances they manufacture or import above one tonne a year. They also have to assess the hazards and potential risks presented by the substance.
                                                    </>
                                                } />

                                                <He3 text={<>Evaluation</>} />

                                                <Text text={
                                                    <>
                                                        Evaluation under KKDIK focuses on three different areas: Examination of testing proposals submitted by registrants Compliance check of the dossiers submitted by registrants Substance evaluation

                                                        <br />

                                                        <Ul list={
                                                            <>
                                                                <Li text={<>
                                                                    Examination of testing proposals submitted by registrants
                                                                </>} />

                                                                <Li text={<>
                                                                    Compliance check of the dossiers submitted by registrants
                                                                </>} />

                                                                <Li text={<>
                                                                    Substance evaluation
                                                                </>} />
                                                            </>
                                                        } />
                                                    </>
                                                } />



                                                <He3 text={<>Authorisation</>} />

                                                <Text text={
                                                    <>
                                                        The authorisation process aims to ensure that substances of very high concern (SVHCs) are progressively replaced by less dangerous substances or technologies where technically and economically feasible alternatives are available. Substances with the following hazard properties may be identified as SVHCs:

                                                        <br />

                                                        <Ul list={
                                                            <>
                                                                <Li text={<>
                                                                    Substances meeting the criteria for classification as carcinogenic, mutagenic or toxic for reproduction (CMR) category 1A or 1B in accordance with the SEA Regulation.
                                                                </>} />

                                                                <Li text={<>
                                                                    Substances which are persistent, bioaccumulative and toxic (PBT) or very persistent and very bioaccumulative (vPvB) according to KKDIK Annex XIII.
                                                                </>} />

                                                                <Li text={<>
                                                                    Substances on a case-by-case basis, that cause an equivalent level of concern as CMR or PBT/vPvB substances.
                                                                </>} />
                                                            </>
                                                        } />
                                                    </>
                                                } />

                                                <Text text={
                                                    <>
                                                        The SVHC identification process includes a 45-day public consultation. Once a substance is identified as an SVHC, it is included in the Candidate List. The inclusion in the Candidate List brings immediate obligations for suppliers of the substance, such as:

                                                        <br />

                                                        <Ul list={
                                                            <>
                                                                <Li text={<>
                                                                    supplying a safety data sheet
                                                                </>} />

                                                                <Li text={<>
                                                                    communicating on safe use
                                                                </>} />

                                                                <Li text={<>
                                                                    responding to consumer requests within 45 days and
                                                                </>} />

                                                                <Li text={<>
                                                                    notifying MOE if the article they produce contains an SVHC in quantities above one tonne per producer/importer per year and if the substance is present in those articles above a concentration of 0.1% (w/w).
                                                                </>} />
                                                            </>
                                                        } />
                                                    </>
                                                } />

                                                <He3 text={<>Restriction</>} />

                                                <Text text={
                                                    <>
                                                        Restrictions are an instrument to protect human health and the environment from unacceptable risks posed by chemicals. Restrictions are normally used to limit or ban the manufacture, placing on the market (including imports) or use of a substance, but can impose any relevant condition, such as requiring technical measures or specific labels.
                                                    </>
                                                } />

                                                <He3 text={<>SCOPE</>} />

                                                <Text text={
                                                    <>
                                                        On 23 June 2017, the Ministry of Environment and Urbanization (MoEU) in Turkey published its REACH similar KKDIK regulation. The KKDIK regulation came into force on 23 Dec 2017. “KKDIK” are the first letters of REACH written in Turkish. Like REACH-EU regulation, the KKDIK regulation requires companies to register all substances manufactured in Turkey or imported into Turkey with volume above 1t/y before a given deadline.
                                                        KKDIK will bring various Turkish chemicals legislation under one law.

                                                        <br />

                                                        <Ul list={
                                                            <>
                                                                <Li text={<>
                                                                    Regulation on the Inventory and Control of Chemicals;
                                                                </>} />

                                                                <Li text={<>
                                                                    Regulation on the Preparation and Distribution of Safety Datasheets for Hazardous Materials and Products; and
                                                                </>} />

                                                                <Li text={<>
                                                                    Regulation on the Restrictions Relating to the Production, Supply to the Market and Use of Certain Hazardous Materials, Products and Goods.
                                                                </>} />
                                                            </>
                                                        } />

                                                        You can review the relevant regulation for legal reference.
                                                    </>
                                                } />

                                                <div className="d-flex justify-content-center">
                                                    <a
                                                        href="https://www.dgroute.com/en/kkdik.html"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="justify-content-center"
                                                    >
                                                        <Image src={kkdiklogo} alt="Logo Kare" classname="w-100" />
                                                    </a>
                                                </div>
                                            </>
                                        }
                                        ish1={true}
                                    />
                                </div>
                            </div >
                        </div>
                    </Scene>
                </Controller>
            </div>
        </>
    );
}