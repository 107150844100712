import { AirPods } from "../img";
import { useImage } from "../hooks";

const AirpodsArray = () => {
  const AA0 = useImage(AirPods[0]);
  const AA1 = useImage(AirPods[1]);
  const AA2 = useImage(AirPods[2]);
  const AA3 = useImage(AirPods[3]);
  const AA4 = useImage(AirPods[4]);
  const AA5 = useImage(AirPods[5]);
  const AA6 = useImage(AirPods[6]);
  const AA7 = useImage(AirPods[7]);
  const AA8 = useImage(AirPods[8]);
  const AA9 = useImage(AirPods[9]);

  const AA10 = useImage(AirPods[10]);
  const AA11 = useImage(AirPods[11]);
  const AA12 = useImage(AirPods[12]);
  const AA13 = useImage(AirPods[13]);
  const AA14 = useImage(AirPods[14]);
  const AA15 = useImage(AirPods[15]);
  const AA16 = useImage(AirPods[16]);
  const AA17 = useImage(AirPods[17]);
  const AA18 = useImage(AirPods[18]);
  const AA19 = useImage(AirPods[19]);

  const AA20 = useImage(AirPods[20]);
  const AA21 = useImage(AirPods[21]);
  const AA22 = useImage(AirPods[22]);
  const AA23 = useImage(AirPods[23]);
  const AA24 = useImage(AirPods[24]);
  const AA25 = useImage(AirPods[25]);
  const AA26 = useImage(AirPods[26]);
  const AA27 = useImage(AirPods[27]);
  const AA28 = useImage(AirPods[28]);
  const AA29 = useImage(AirPods[29]);

  const AA30 = useImage(AirPods[30]);
  const AA31 = useImage(AirPods[31]);
  const AA32 = useImage(AirPods[32]);
  const AA33 = useImage(AirPods[33]);
  const AA34 = useImage(AirPods[34]);
  const AA35 = useImage(AirPods[35]);
  const AA36 = useImage(AirPods[36]);
  const AA37 = useImage(AirPods[37]);
  const AA38 = useImage(AirPods[38]);
  const AA39 = useImage(AirPods[39]);

  const AA40 = useImage(AirPods[40]);
  const AA41 = useImage(AirPods[41]);
  const AA42 = useImage(AirPods[42]);
  const AA43 = useImage(AirPods[43]);
  const AA44 = useImage(AirPods[44]);
  const AA45 = useImage(AirPods[45]);
  const AA46 = useImage(AirPods[46]);
  const AA47 = useImage(AirPods[47]);
  const AA48 = useImage(AirPods[48]);
  const AA49 = useImage(AirPods[49]);

  const AA50 = useImage(AirPods[50]);
  const AA51 = useImage(AirPods[51]);
  const AA52 = useImage(AirPods[52]);
  const AA53 = useImage(AirPods[53]);
  const AA54 = useImage(AirPods[54]);
  const AA55 = useImage(AirPods[55]);
  const AA56 = useImage(AirPods[56]);
  const AA57 = useImage(AirPods[57]);
  const AA58 = useImage(AirPods[58]);
  const AA59 = useImage(AirPods[59]);

  const AA60 = useImage(AirPods[60]);
  const AA61 = useImage(AirPods[61]);
  const AA62 = useImage(AirPods[62]);
  const AA63 = useImage(AirPods[63]);
  const AA64 = useImage(AirPods[64]);
  const AA65 = useImage(AirPods[65]);
  const AA66 = useImage(AirPods[66]);
  const AA67 = useImage(AirPods[67]);
  const AA68 = useImage(AirPods[68]);
  const AA69 = useImage(AirPods[69]);

  const AA70 = useImage(AirPods[70]);

  const newImages = Array.of(
    AA0,
    AA1,
    AA2,
    AA3,
    AA4,
    AA5,
    AA6,
    AA7,
    AA8,
    AA9,

    AA10,
    AA11,
    AA12,
    AA13,
    AA14,
    AA15,
    AA16,
    AA17,
    AA18,
    AA19,

    AA20,
    AA21,
    AA22,
    AA23,
    AA24,
    AA25,
    AA26,
    AA27,
    AA28,
    AA29,

    AA30,
    AA31,
    AA32,
    AA33,
    AA34,
    AA35,
    AA36,
    AA37,
    AA38,
    AA39,

    AA40,
    AA41,
    AA42,
    AA43,
    AA44,
    AA45,
    AA46,
    AA47,
    AA48,
    AA49,

    AA50,
    AA51,
    AA52,
    AA53,
    AA54,
    AA55,
    AA56,
    AA57,
    AA58,
    AA59,

    AA60,
    AA61,
    AA62,
    AA63,
    AA64,
    AA65,
    AA66,
    AA67,
    AA68,
    AA69,

    AA70,
  );
  return newImages;
};

export default AirpodsArray;
