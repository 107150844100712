import React, { FC } from 'react';

interface TitleProps {
    text: object;
}

const Li: FC<TitleProps> = ({ text }) => {
    return (
        <li>
            <p>
                {text}
            </p>
        </li>
    );
};

export default Li;