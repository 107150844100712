import React from "react";
import kkdiklogo from './media/kkdik-logo.png';
import TextCard from "./components/TextCard"
import Image from "./components/Image";
import Link from "./components/Link";
import Text from "./components/Text"
import He3 from "./components/He3"
import Ul from "./components/Ul";
import Li from "./components/Li";
import { Controller, Scene } from "react-scrollmagic";
import { Helmet } from "react-helmet";

export function Services4() {
    return (
        <>
            <Helmet>
                <title>KKDIK Pre-Registration Services</title>
                <meta name="description" content="On June 23, 2017 KKDIK – Turkish REACH regulation was published by the Turkish Republic Ministry of Environment in the Official Gazette No. 30105 and came into force on December 23, 2017." />
            </Helmet>
            <div>
                <Controller>
                    <Scene classToggle="after" triggerHook={0.7} reverse={true}>
                        <div className="before">
                            <div className="container">
                                <div className="row justify-content-center my-3">
                                    <TextCard
                                        css={{ backgroundColor: "#ffffff", marginTop: "5rem", padding: "4rem" }}
                                        csstext={{ color: "black", fontSize: "1.5rem", textAlign: "justify" }}
                                        csstitle={{ color: "black", fontSize: "3rem" }}
                                        header="KKDIK Pre-Registration Services"
                                        text={
                                            <>
                                                <Text text={<>
                                                    On June 23, 2017 <Link to="/kkdik" css={{}} className={""} Text={"KKDIK"} /> – <Link to="/treach1" css={{}} className={""} Text={"Turkish REACH regulation"} /> was published by the  Turkish Republic Ministry of Environment in the Official Gazette No. 30105 and came into force on December 23, 2017.​ KKDIK Pre-Registration Service
                                                    <br /><br />
                                                    KKDIK has been prepared on the basis of EU REACH Regulation and adapted to the Turkish Industry within the frame of compliance with the legislation of the European Union.
                                                    <br /><br />
                                                    Registration, Restriction and Permission are similar processes within the scope of  KKDIK – Turkish REACH.
                                                    <br /><br />
                                                    Registration within the scope of KKDIK are divided into two process named as Pre-Registration and Registration
                                                    <br /><br />
                                                    European REACH registration of substances shall not be applied in Turkey. Therefore, these substances should be registered under the KKDIK again.
                                                    <br /><br />
                                                    The first step, the KKDIK Pre-Registration Process, will be held between 23 December 2017 to 31 December 2020. After the pre-registration process will be completed on 31 December 2020, the registration process will start.
                                                    <br /><br />
                                                    We can brief critical dates for these processes as below;
                                                </>} />

                                                <br />

                                                <Ul list={
                                                    <>
                                                        <Li text={<>
                                                            <b>23 December 2017- 31 December 2020:</b> Pre-Registration will be held for the substances which are evaluated under KKDIK.
                                                        </>} />

                                                        <Li text={<>
                                                            <b>01 January 2021 – Beyond:</b> The main registration process will start for the substances evaluated under KKDIK. However, there are important dates that should be specified for this period.
                                                        </>} />

                                                        <Li text={<>
                                                            <b>01 January 2021 – 31 December 2023:</b> The registration process for substances evaluated under KKDIK will start. However, as the registration process continues, pre-registered substances can be produced or imported between these dates. Substances that are not previously registered may be produced or imported in the event of completion of the registration process.
                                                        </>} />

                                                        <Li text={<>
                                                            <b>01 January 2024 – Beyond:</b> The substances within the scope of KKDIK can be produced or imported on the condition that the registration process is completed from 01 January 2024.
                                                        </>} />

                                                        <Li text={<>
                                                            The restriction provisions came into force on 23 December 2017. Other provisions shall be effective as from this date. Furthermore, all restrictions shall be effective on 31 December 2023.
                                                        </>} />
                                                    </>
                                                } />

                                                <br />

                                                <He3 text={<>KKDIK Registrations:</>} />

                                                <Text text={<>
                                                    In order to prepare for the registration process, the process defined as the pre-registration process is called the pre-Substance Information Exchange forum (pre-SIEF) in the KKDIK regulation.
                                                    <br /><br />
                                                    Some of the relevant articles for Pre-Registration, that are mentioned as Pre-Substance Information Exchange Forum (pre-SIEF) in the KKDIK Regulation, are specified as follows;
                                                    <br /><br />
                                                    TRANSITIONAL ARTICLE 1- (1) All registrants, shall send a pre-SIEF including below mentioned information to the Ministry through Chemicals Registration System in the website of Ministry until 31/12/2020:
                                                    a) Substance identity according to Annex-6;
                                                    b) Role in the supply chain
                                                    <br /><br />
                                                    Entry into Force
                                                    <br /><br />
                                                    ARTICLE 66- (1) ç) All other provisions will enter into force 6 months later the date of its publication on the Official Gazette.
                                                    As stated in the KKDIK Regulation, it is obligatory to submit Pre-SIEF (Pre-Registration) for chemical substances with registration obligation under the scope of KKDIK. This obligation comes into force as of December 23, 2017. The deadline for submission of the Pre-SIEF to the Ministry’s assessment is 31 December 2020.
                                                    <br /><br />
                                                    There is a Pre-Registration / Registration obligations for substances manufactured or imported in 1 ton or more per year in its own form or in the mixture.
                                                </>} />

                                                <div className="d-flex justify-content-center">
                                                    <a
                                                        href="https://www.dgroute.com/en/kkdik.html"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="justify-content-center"
                                                    >
                                                        <Image src={kkdiklogo} alt="Logo Kare" classname="w-100" />
                                                    </a>
                                                </div>

                                                <Text text={
                                                    <>
                                                        <Link to="/contact" css={{}} className={""} Text={"Do not hesitate to contact us for comprehensive information and offer about KKDIK Pre-Registration Service and Turkish REACH Regulations."} />
                                                    </>
                                                } />
                                            </>
                                        }
                                        ish1={true}
                                    />
                                </div>
                            </div >
                        </div>
                    </Scene>
                </Controller>
            </div>
        </>
    );
}