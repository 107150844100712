import React, { forwardRef } from "react";
import ImageArray from "./ImageArray";
import TextCard from "../components/TextCard";
import Text from "../components/Text";

const ImageSequence = forwardRef(({ progress }, ref) => {
  const newImages = ImageArray();

  let index = Math.round(progress * 1 * (newImages.length - 1));

  if (newImages[index], [1] === "loading") {
  } else {
    return (
      <div
        ref={ref}
        key={index}
        style={{
          display: "block",
          minHeight: "100vh",
          minWidth: "100%",
          backgroundImage: `url('${newImages[index][0] && newImages[index][0].src}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}>
        <div className="container">
          <div className="row justify-content-center" style={{ height: "30vh" }}>

          </div>

          <div className="row justify-content-center mb-20">
            <div className="col-md-9">
              <TextCard
                css={{ backgroundColor: "" }}
                csstext={{ color: "black", fontSize: "1.5rem", textAlign: "justify" }}
                csstitle={{ color: "black", fontSize: "3rem" }}
                header="T-REACH (KKDIK) OR Service"
                text={<>Since 2013, Turkey harmonized major chemical regulations including CLP [OG 11.12.2013 – 28848] and REACH [OG 13.12.2014 – 29204 & OG 23.06.2017 – 30105] in effort to align its chemical regulatory status with the European Union (EU).</>}
                ish1={true} />
            </div>
            <div className="col-md-3">

            </div>
          </div>

          <div className="row justify-content-center mt-20">
            <div className="col-md-12">
              <Text text={<>To place a product on the Turkish market, companies are now required to comply with an extensive list of legal obligations mainly pertaining to classification, labelling and packaging of chemicals. Managing your compliance needs in this evolving regulatory landscape might prove a complicated task and that’s where we come in.</>} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
);

export default ImageSequence;
