import React, { FC } from 'react';
import Image from "./Image";

interface TitleProps {
    href: string;
    src: string;
    alt: string;
    classname: string;
}

const TextImage: FC<TitleProps> = ({ href, src, alt, classname }) => {
    return (
        <div className="d-flex justify-content-center">
            <a
                href={href}
                target="_blank"
                rel="noreferrer"
                className="justify-content-center"
            >
                <Image src={src} alt={alt} classname={classname} />
            </a>
        </div>
    );
};

export default TextImage;