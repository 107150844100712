import React from "react";
import kkdiklogo from './media/kkdik-logo.png';
import logokare from './media/logo-kare.jpg';
import TextCard from "./components/TextCard"
import Link from "./components/Link";
import TextImage from "./components/TextImage";
import Text from "./components/Text"
import He3 from "./components/He3"
import "./styles.css"
import { Controller, Scene } from "react-scrollmagic";
import { Helmet } from "react-helmet";

export function Services1() {
    return (
        <>
            <Helmet>
                <title>Services | GHS Compliance</title>
                <meta name="description" content="DGROUTE​ offers Turkey only representative services, Turkish SDS authoring, translation and compliance services." />
            </Helmet>
            <div>
                <Controller>
                    <Scene classToggle="after" triggerHook={0.7} reverse={true}>
                        <div className="before">
                            <div className="container">
                                <div className="row justify-content-center my-3">
                                    <TextCard
                                        css={{ backgroundColor: "#ffffff", marginTop: "5rem", padding: "4rem" }}
                                        csstext={{ color: "black", fontSize: "1.5rem", textAlign: "justify" }}
                                        csstitle={{ color: "black", fontSize: "3rem" }}
                                        header="Services"
                                        text={
                                            <>
                                                <TextImage href="https://www.dgroute.com/en/kkdik.html" src={kkdiklogo} alt="KKDİK Logo" classname="" />

                                                <He3 text={<>Turkish REACH regulation</>} />
                                                <He3 text={<>Registration</>} />
                                                <Text text={
                                                    <>
                                                        <a
                                                            href="https://www.dgroute.com/en/t_reach_only_representative_kkdik.html"
                                                            target="_blank"
                                                            className="text-primary"
                                                            rel="noreferrer"><b>DGROUTE​</b></a> offers Turkey only representative services, Turkish SDS authoring, translation and compliance services for all chemical industry in Turkey and abroad. Many aspects of the Global Harmonized Systems (GHS) have already been implemented in Turkey for Turkey chemical industry.
                                                        <br /><br />
                                                        The GHS Regulation came into force when The Turkey Official Gazette published the GHS Regulations with the set transitional periods in December 2014. The full implementation of the regulation has been implemented as of June 1, 2016 for both hazardous substances and mixtures. An SDS (formerly known as MSDS) includes information such as the properties of each chemical; the physical, health, and environmental health hazards; protective measures; and safety precautions for handling, storing, and transporting the chemical.
                                                    </>
                                                } />

                                                <He3 text={<><Link to="/" css={{}} className={""} Text={"Turkey OR services"} /></>} />
                                                <Text text={<>DGROUTE offers Turkey Only Representative services consultation on issues relating to KKDİK (Turkish REACH), Pre-SIEF, SIEF& Consortia Management, SDS authoring solutions.</>} />

                                                <TextImage href="https://www.dgroute.com/en/kkdik.html" src={logokare} alt="Logo Kare" classname="" />

                                                <Text text={<><Link to="/contact" css={{}} className={""} Text={"Do not hesitate to contact us for comprehensive information and offer about KKDIK Pre-Registration Service and Turkish REACH Regulations."} /></>} />
                                            </>
                                        }
                                        ish1={true}
                                    />
                                </div>
                            </div >
                        </div>
                    </Scene>
                </Controller>
            </div>
        </>
    );
}