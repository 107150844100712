import React, { FC } from 'react';
import Image from './Image';
import Anchor from './Link';

interface TitleProps {
    img: string;
    to: string;
    buttonText: string;
    isExternal: boolean;

}

const Card: FC<TitleProps> = ({ img, to, buttonText, isExternal }) => {
    let Content
    if (!isExternal) {
        Content = <Anchor to={to} css={{}} className={"btn btn-dark btn-sm"} Text={buttonText} />
    } else {
        Content = <a href={to} target={"blank"} className={"btn btn-dark btn-sm"}> {buttonText}</a >
    }
    return (
        <div className="card-body p-10">
            <div className="overlay-wrapper">
                <Image src={img} alt={"logokare"} classname={"w-100 rounded"} />
            </div>
            <div className="overlay-layer align-items justify-content-center">
                <div className="d-flex flex-grow-1 flex-center py-5">
                    {Content}
                </div>
            </div>
        </div >
    );
};

export default Card;