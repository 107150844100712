/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useLayout } from '../core'
import { Link } from 'react-router-dom'

const Footer: FC = () => {
  const { classes } = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer' style={{ backgroundColor: "#000000", zIndex: "1" }}>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-white fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href='https://www.dgroute.com/en/' target="blank" className='text-white text-hover-primary'>
            DGRoute
          </a>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item'>
            <Link to="/about" className='menu-link ps-0 pe-2 mx-2 text-white'>About</Link>
          </li>
          <li className='menu-item'>
            <Link to="/contact" className='menu-link ps-0 pe-2 mx-2 text-white'>Contact</Link>
          </li>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export { Footer }
