import React from "react";
import logokare from './media/logo-kare.jpg';
import TextCard from "./components/TextCard"
import Link from "./components/Link";
import TextImage from "./components/TextImage";
import Text from "./components/Text"
import He3 from "./components/He3"
import { Controller, Scene } from "react-scrollmagic";
import { Helmet } from 'react-helmet';

export function About() {
    return (
        <>
            <Helmet>
                <title>About | GHS Compliance</title>
                <meta name="description" content="DGROUTE provides services in the field of Only Representative, chemicals and dangerous goods logistics with expert staff certified by government agencies." />
            </Helmet>
            <div>
                <Controller>
                    <Scene classToggle="after" triggerHook={0.7} reverse={true}>
                        <div className="before">
                            <div className="container">
                                <div className="row justify-content-center my-3">
                                    <TextCard
                                        css={{ backgroundColor: "#ffffff", marginTop: "5rem", padding: "4rem" }}
                                        csstext={{ color: "black", fontSize: "1.5rem", textAlign: "justify" }}
                                        csstitle={{ color: "black", fontSize: "3rem" }}
                                        header="About"
                                        text={
                                            <>
                                                <TextImage href="https://www.dgroute.com/en/kkdik.html" src={logokare} alt="Logo Kare" classname="img-fluid" />

                                                <Text text={
                                                    <>
                                                        DGROUTE provides services in the field of <Link to="/services1" css={{}} className={""} Text={"Only Representative"} />, chemicals and dangerous goods logistics with expert staff certified by government agencies.

                                                        DGROUTE provides service with its expert staff who Chemical Evaluation Specialist certificate. We provide Safety Data Sheet (MSDS/SDS) preparation and alignment services in 52 languages in compliance with current regulations.

                                                        DGROUTE offers Only Representative services consultation on issues relating to KKDİK (Turkish REACH ), Pre-SIEF, SIEF& Consortia Management, SDS authoring solutions.

                                                        Our company operates from its headquarters in the Scientific and Technological Research Council of Turkey campus. We carry out R&D studies in the fields of chemistry and dangerous goods logistics and develop software solutions.

                                                        <He3 text={<>Our Mission</>} />
                                                        <Text text={<>DGROUTE is the reliable business partner of its customers with its service and software solutions in the field of chemical legislation management.</>} />

                                                        <He3 text={<>Our Vision</>} />
                                                        <Text text={<>DGROUTE believes innovation and collaboration is an indispensable value. As a brand that develops customer-oriented approaches with its sustainable service quality, it is our target to be your solution partner.</>} />
                                                    </>
                                                } />
                                            </>
                                        }
                                        ish1={true}
                                    />
                                </div>
                            </div >


                        </div>
                    </Scene>
                </Controller>
            </div>
        </>
    );
}